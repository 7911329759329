import React from 'react'
import {
  Card,
  Text,
  Image,
  Button,
  Center,
} from '@mantine/core';
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import tutor from '../../../public/tutor.png' 
import { RxCross2 } from "react-icons/rx";

const ComingSoon = () => {

  const navigate = useNavigate();
  const arrowBack = () => {
    navigate('/tutorlearnai');
  }
//   const gotonextscreen = () => {
//     navigate('/tutorlearnai');
//   }


  return (
    <div>
      <div style={{
        position: "fixed",
        width: "100%",
        height: "8%",
        // borderRadius: "30px",
        maxWidth: "600px",
        top: "55px",
        // background: "white",
        padding: "0px",
        display: "grid",
        alignItems: "center",
        justifyContent: "'center",
        alignContent: "center",
        backgroundColor: "white"
      }}>



        <div>
          <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
        </div>
        <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
           AI Tutor
          </Text>
        </div>
      </div>

      
      <Center style={{paddingTop: "150px"}}>
      <Text style={{ fontWeight: "500", fontSize:"40px", textAlign: "center" }}>
      Coming Soon
      </Text>
      </Center>

     

      <Button onClick={arrowBack} style={{width: "50%", marginLeft: "25%", marginTop:"100px"}} mb={"10px"} color='#0180ff' >
      Back
                    </Button>

         <div style={{marginTop: "60px"}}>
                 <h6 style={{color:'white'}}>.</h6>
                </div>   
        

      
    </div>
  )
}

export default ComingSoon
